body, html {
  height: 100%;
}

.options-container {
  width: 100%;
  height: 100%;
}

.view-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;     /* Center vertically */
  height: 100%;           /* Adjust height as needed */
}

.flex-grow-container {
  flex-grow: 1;
  padding: 1rem;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  width: 90%;
  overflow-y: auto;
  align-items: start;
  justify-content: center; /* Centers children horizontally in the container */
  padding: 3rem;
}

.gray-container {
  background-color: #f0f0f0; /* Light grey, adjust as needed */
  border-radius: 5px;
}

.grid-item-container {
  display: flex;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  align-items: center;
  width: 100%; /* Ensure the container fills its parent */
  border-radius: 5px;
}

.label-container {
  flex: 1; /* Allow the label to fill half the space */
  max-width: 45%; /* Maximum width of 50% */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

.select-input-container {
  text-align: end;
  box-sizing: border-box; /* Include padding and border in the element's total width */
  flex: 1; /* Allow the select/input to fill the remaining half */
  max-width: 55%; /* Maximum width of 50% */
}

.input-bg-color {
  background-color: white; 
  border-radius: 5px;
}

.settings-button-set {
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.centered-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.centered-text-container > * {
  display: block
}

.margin-0 {
  margin: 0rem !important;
}

.margin-bottom {
  margin-top: 0rem !important;
  margin-bottom: 1rem !important;
}

.margin-bottom-2x {
  margin-top: 0rem !important;
  margin-bottom: 2rem !important;
}

.hundred-percent-height {
  height: 100%;
}

.hundred-percent-width-centered {
  justify-content: center; /* Centers children horizontally in the container */
  display: flex;
  width: 100%;
}
.main-h {
  margin-top: 0.6rem !important;
  margin-bottom: 0.8rem !important;
}

.dropdown-add {
  background-color: white !important; 
  border-radius: 5px !important;
  border-color: black !important;
}

.dropdown-add > * {
  color: black !important;
}

/* Center the image */
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    min-height: 100vh; /* Set the height of the container to the viewport height */
}

.center > * {
  margin: 1.5rem; /* Adjust margin as needed */
}

  /* Set max-width for the image */
img {
    max-width: 100%;
    max-height: 100%;
}